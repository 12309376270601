<template>
  <div class="app-members-edit">
    <Module-Header title="Edit Notice " :leading="true">
      <el-button class="delete" @click="del"><img src="@/assets/images/delete-01.png" />Delete</el-button>
    </Module-Header>

    <div class="compulsory">
      <span class="label">*Compulsory</span>
      <span class="value">Last Published {{ initData.publicAt | formatDateTime }}</span>
    </div>

    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
     label-width="200px" class="ruleForm" label-position="left">
      <el-form-item label="Title (TC)" prop="title_tc">
        <el-input v-model="ruleForm.title_tc" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Title (EN)" prop="title_en">
        <el-input v-model="ruleForm.title_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Description (TC)">
        <el-input v-model="ruleForm.description_tc" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Description (EN)">
        <el-input v-model="ruleForm.description_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Display Date">
        <Date-picker :joiningDate="ruleForm.date" @updateDate="s => ruleForm.date = s"></Date-picker>
      </el-form-item>
      <el-form-item label="Display Time">
        <Time-Picker :joiningDate="ruleForm.time" @updateTime="s => ruleForm.time = s"></Time-Picker>
      </el-form-item>

      <div class="content">
        <span>Content</span>
        <img src="@/assets/images/bubble.png" alt="">
      </div>
      <el-form-item label="Traditional Chinese" prop="content_tc">
        <el-input type="textarea" v-model="ruleForm.content_tc" autocomplete="off" placeholder="Please input here..."></el-input>
      </el-form-item>
      <el-form-item label="English" prop="content_en">
        <el-input type="textarea" v-model="ruleForm.content_en" autocomplete="off" placeholder="Please input here..."></el-input>
      </el-form-item>
    </el-form>

    <el-button @click="save">Save & Publish</el-button>
  </div>
</template>

<script>
import TimePicker from '@/components/DatePicker/timePicker.vue';
import { editNotices, getNoticesDetail, delNotices } from '@/api/notices'

export default {
  components: {
    TimePicker
  },
  data() {
    return {
      initData: {},
      ruleForm: {
        title_tc: '',
        title_en: '',
        description_tc: '',
        description_en: '',
        date: '',
        time: '',
        content_tc: '',
        content_en: '',
      },
      rules: {
        title_tc: [
          { required: true, message: 'Please Enter Title (TC)', trigger: 'blur' },
        ],
        title_en: [
          { required: true, message: 'Please Enter Title (EN)', trigger: 'blur' },
        ],
        content_tc: [
          { required: true, message: 'Please Enter Traditional Chinese', trigger: 'blur' },
        ],
        content_en: [
          { required: true, message: 'Please Enter English', trigger: 'blur' },
        ],
      },
    }
  }, 
  methods: {
    del() {
      this.$popUp.config({
        content: 'Please confirm whether to Delete',
        confirm: {
          e: () => {
            let { id } = this.$route.params
            delNotices(id).then(() => {
              this.$router.go(-1)
              this.$popUp.removePopup()
            })
          }
        }
      })
    },

    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.doSave()
        }
      })
    },
    doSave() {
      let { ruleForm } = this,
      form = {
        'id': ruleForm.id,
        "title": {
          "zh_hant": ruleForm.title_tc,
          "en": ruleForm.title_en
        },
        "description": {
          "zh_hant": ruleForm.description_tc,
          "en": ruleForm.description_en
        },
        "content": {
          "zh_hant": ruleForm.content_tc,
          "en": ruleForm.content_en
        },
        "display_at": ruleForm.date + ' ' + ruleForm.time
      }
      
      this.$popUp.config({
        content: 'Please confirm whether to Publish',
        confirm: {
          e: () => {
            let { id } = this.$route.params
            editNotices(id, form).then(() => {
              this.$message.success('Successfully Saved')
              this.$router.go(-1)
              this.$popUp.removePopup()
            })
          }
        }
      })
    },
    getData() {
      let { id } = this.$route.params
      getNoticesDetail(id).then(v => {
        let data = window.toCamelCase(v.data)
        this.initData = data
        
        this.ruleForm = {
          id: data.id,
          title_tc: data.title.zhHant,
          title_en: data.title.en,
          description_tc: data.description.zhHant,
          description_en: data.description.en,
          date: data.displayAt ? data.displayAt.split(' ')[0].replace(/-/g, '/') : '',
          time: data.displayAt ? data.displayAt.split(' ')[1] : '',
          content_tc: data.content.zhHant,
          content_en: data.content.en,
        }
      })
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.compulsory {
  font-size: 1.125rem;
  color:#1B7379;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.1875rem;
  margin-top: 1.875rem;
}
.ruleForm {
  margin-top: 1.875rem;
  width: 67%;
  .content {
    width: 100%;
    color:#1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1.875rem;
    position: relative;

    & > img {
      position: absolute;
      left: 100%;
      bottom: calc(100% - 3.125rem);
      width: 11.25rem;
      height: auto;
    }
  }
}
</style>