import http from '@/utils/http'

export function getNotices(params){
  return http('/notices', {params})
}

export function addNotices(data){
  return http.post('/notices', data)
}

export function getNoticesDetail(id){
  return http(`/notices/${id}`)
}

export function editNotices(id, data){
  return http.put(`/notices/${id}`, data)
}

export function delNotices(id){
  return http.delete(`/notices/${id}`)
}